@use '../../../scss/variable/z-index';
@use '../../../scss/variable/transition';

.Backdrop {
  z-index: z-index.$top-4;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-color: rgba(23, 31, 15, 0.48);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  display: flex;
  flex-direction: column;

  &_open {
    pointer-events: all;
    opacity: 1;
    visibility: visible;
    cursor: default;
  }

  &_easing {
    transition: all transition.$slower;
  }
}
