@use '../../../../../scss/variable/breakpoint';
@use '../../../../../stories/scss/settings/spacing';
@use '../../../../../scss/variable/color';
@use '../../../../../scss/variable/font';
@use '../../../../../scss/variable/shadow';
@use '../../../../../scss/mixin/flexbox';

.wrapper {
  @include flexbox.flex-align-justify($_flex__justify-content: center);
  height: 100%;
  position: relative;
}

.menu {
  &.desktop {
    position: absolute;
    display: block;
    cursor: initial;

    top: 100%;
    right: 0;

    min-width: 280px;
    padding: spacing.$s6 spacing.$s4 spacing.$s4 spacing.$s4;

    border-radius: var(--theme-border-radius-md);
    box-shadow: shadow.$drop-darker-bottom;
    background: var(--theme-color-background);

    &.authorized {
      min-width: 260px;
    }
  }

  .subtitle {
    font-size: font.$size-xxs;
    color: var(--theme-font-color-lighter);
  }

  .actions {
    display: flex;
    flex-direction: column;

    margin-top: spacing.$s2;
  }

  .action {
    display: flex;
    align-items: center;
    padding: spacing.$s2 11px;
    border-radius: var(--theme-border-radius-sm);
    font-size: font.$size-base;
    cursor: pointer;

    margin-left: -(spacing.$s2);
    margin-right: -(spacing.$s2);

    .icon {
      @include flexbox.flex-align-justify($_flex__justify-content: center);
      width: 24px;
      height: 24px;
      margin-right: 6px;
      padding-bottom: spacing.$s1;
    }

    &:hover {
      color: var(--theme-color-secondary);
      background: var(--theme-color-secondary-light);
    }
  }

  .register {
    text-align: center;
    margin-top: spacing.$s4;

    &-link {
      margin-top: 3px;
      cursor: pointer;
      color: var(--theme-color-secondary);
    }
  }
}

.name {
  display: none;
  margin-right: spacing.$s2;
  user-select: none;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (min-width: breakpoint.$md) {
    max-width: 180px;
    display: block;
  }

  @media screen and (min-width: breakpoint.$lg) {
    max-width: 90px;
  }

  @media screen and (min-width: breakpoint.$xl) {
    max-width: 135px;
  }

  @media screen and (min-width: breakpoint.$xxl) {
    max-width: 180px;
  }
}
