@use '../../../scss/variable/breakpoint';
@use '../../../scss/variable/transition';

@keyframes slide-right-animation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-left-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.sideBar{
  touch-action: none;

  @media screen and (min-width: breakpoint.$min) and (max-width: breakpoint.$md){
    border-radius: 16px 16px 0 0 ;
    margin-top: 24px;
    width: 100vw;

    &:not(.no-align) {
      text-align: center;
    }
  }

  /**
   * Keep these animation times in line with the Generic Backdrop and the timeout in SideBar.tsx
   */
  &--slide-open {
    visibility: visible;
    @media screen and (min-width: breakpoint.$lg){
      animation: slide-right-animation transition.$slower forwards;
    }
  }

  &--slide-close {
    visibility: visible;
    @media screen and (min-width: breakpoint.$lg){
      animation: slide-left-animation transition.$slower forwards;
    }
  }

}
