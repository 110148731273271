@use '../../../../../../../scss/variable/breakpoint';
@use '../../../../../../../stories/scss/settings/spacing';
@use '../../../../../../../scss/variable/font';
@use '../../../../../../../scss/mixin/flexbox';

.header {
  @include flexbox.flex-align-justify();
  padding: spacing.$s4 spacing.$s4 0 spacing.$s4;

  @media screen and (min-width: breakpoint.$md) {
    padding: spacing.$s4 spacing.$s4 spacing.$s2 spacing.$s4;
  }

  .title {
    font-size: font.$size-xxs;
    color: #171F0F52;
  }

  .hidden {
    visibility: hidden;
  }
}
